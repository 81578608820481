// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box; 
  margin: 0px;
  padding: 0px;
  outline: none;
  border: 0px;
}

.App{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

#Body{
  width: 100%;
  height: fit-content;
}

#lightFooter{
  display: flex;
  width: 100%;
  padding: 15px 0px;
  border-width: 1px 0 0;
  border-style: solid;
  align-items: center;
}

#darkFooter{
  display: flex;
  width: 100%;
  padding: 15px 0px;
  border-width: 1px 0 0;
  border-color: grey;
  border-style: solid;
  align-items: center;
}

#darkFooter #footer_logo{
  opacity: 0.7;
}

#footer_div{
  width: 12rem;
  margin-left: 10px;
}

#footer_logo{
  width: 100%;
}

#SearchBar{
  width: fit-content;
  margin-left: 3rem;
}

#light {
  background-color: #f5f5f5;
}

#dark {
  background-color: #121212;
}

@media only screen and (max-width: 616px) {
  #footer_div{
    width: 30vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;EACjB,qBAAqB;EACrB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":["* {\n  box-sizing: border-box; \n  margin: 0px;\n  padding: 0px;\n  outline: none;\n  border: 0px;\n}\n\n.App{\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 100vh;\n}\n\n#Body{\n  width: 100%;\n  height: fit-content;\n}\n\n#lightFooter{\n  display: flex;\n  width: 100%;\n  padding: 15px 0px;\n  border-width: 1px 0 0;\n  border-style: solid;\n  align-items: center;\n}\n\n#darkFooter{\n  display: flex;\n  width: 100%;\n  padding: 15px 0px;\n  border-width: 1px 0 0;\n  border-color: grey;\n  border-style: solid;\n  align-items: center;\n}\n\n#darkFooter #footer_logo{\n  opacity: 0.7;\n}\n\n#footer_div{\n  width: 12rem;\n  margin-left: 10px;\n}\n\n#footer_logo{\n  width: 100%;\n}\n\n#SearchBar{\n  width: fit-content;\n  margin-left: 3rem;\n}\n\n#light {\n  background-color: #f5f5f5;\n}\n\n#dark {\n  background-color: #121212;\n}\n\n@media only screen and (max-width: 616px) {\n  #footer_div{\n    width: 30vw;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
