// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
    --branchcolor: black;
    --zoomcolor: black;
}

.vocabulary-explorer {
    font-size: 36px;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    color: rgb(0, 0, 0);
    margin: 50px 0;
}

.search-bar {
    margin-left: 50px;
    margin: 25px 0;
}

.rd3t-link{
    stroke: var(--branchcolor) !important;
}

.zoomGroup .MuiButtonGroup-grouped:not(:last-of-type){
    border-color: var(--zoomcolor) !important;
}

.zoomGroup .css-d4hd0p-MuiButtonBase-root-MuiButton-root{
    color: var(--zoomcolor) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/WordTree/styles/WordTree.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,oCAAoC;IACpC,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":[":root{\n    --branchcolor: black;\n    --zoomcolor: black;\n}\n\n.vocabulary-explorer {\n    font-size: 36px;\n    font-weight: bold;\n    font-family: 'Open Sans', sans-serif;\n    text-align: center;\n    color: rgb(0, 0, 0);\n    margin: 50px 0;\n}\n\n.search-bar {\n    margin-left: 50px;\n    margin: 25px 0;\n}\n\n.rd3t-link{\n    stroke: var(--branchcolor) !important;\n}\n\n.zoomGroup .MuiButtonGroup-grouped:not(:last-of-type){\n    border-color: var(--zoomcolor) !important;\n}\n\n.zoomGroup .css-d4hd0p-MuiButtonBase-root-MuiButton-root{\n    color: var(--zoomcolor) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
