// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-sghohy-MuiButtonBase-root-MuiButton-root:hover{
    background-color: #1976d2 !important;
    cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchAppBar/SearchAppBar.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,eAAe;AACnB","sourcesContent":[".css-sghohy-MuiButtonBase-root-MuiButton-root:hover{\n    background-color: #1976d2 !important;\n    cursor: default;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
